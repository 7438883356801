import {
  Admin,
  Resource,
  ListGuesser,
  CustomRoutes,
  fetchUtils,
} from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import { Route } from "react-router-dom";
import authProvider from "./authProvider";
import Prices from "./prices";
import SendSms from "./sendSms";
import Import from "./import";
import Transactions from "./asd";
import simpleRestProvider from "ra-data-simple-rest";
import CheckIn from "./checkin";


// const apiUrl = "https://localhost:44346";
const apiUrl = "https://api.easypoint.com.tr";

const dataProvider = simpleRestProvider(apiUrl);
const { http } = dataProvider;


const httpClient = fetchUtils.fetchJson;

const appDataProvider = {
  ...dataProvider,

  async BoxList() {
    const url = `${apiUrl}/${"mailapi/mail-list"}`;
    return httpClient(url, {
      headers : new Headers({ Authorization: "Basic YWRtaW46NDQ1NTY2TXVoYXNlYmU=" })
    }).then(({ headers, json }) => ({
      data: json,
    }));
  },

  
  async SendMail(data : any) {
    const url = `${apiUrl}/${"mailapi/sendMail"}`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers : new Headers({ Authorization: "Basic YWRtaW46NDQ1NTY2TXVoYXNlYmU=" })
    }).then(({ json }) => ({
      data: { ...json },
    }));
  },



  
  // async BoxList() {
  //   const url = `${apiUrl}/${"mailapi/mail-list"}`;
  //   return httpClient((url: string, options: any = {}) =>{
  //     if (!options?.headers) {
  //       options.headers = new Headers({ Accept: "application/json" });
  //       options.headers.set('Authorization', `Basic YWRtaW46NDQ1NTY2TXVoYXNlYmU=`);
  //     }
  //     return fetchUtils.fetchJson(url, options);
  //   }).then(({ headers, json }) => ({
      
  //     data: json,
  //   }));
  // },


  async SendSms(data : any) {
    const url = `${apiUrl}/${"other-services/box-sms"}`;
    return httpClient(url, {
      method: "POST",
      body: data,
    }).then(({ json }) => ({
      data: { ...json },
    }));
  },

  
  async checkBox(boxNumber: string, locationCode: string, organisation: string) {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/checkBox2/${boxNumber}/${locationCode}/${organisation}`, {
        method: "GET",
      })
      .then(({ json }) => ({
        data: json,
      }));
  },

  async checkBoxforBuyTime(boxNumber: string, _orderType: number) {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/checkBoxforBuyTime/${boxNumber}`, {
        method: "GET",
      })
      .then(({ json }) => ({
        data: json,
      }));
  },

  async getDurations() {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/durations`, {
        method: "GET",
      })
      .then(({ json }) => ({
        data: json,
      }));
  },

  async sendOTPCode(model: any) {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/verification-code`, {
        method: "POST",
        body: JSON.stringify(model),
      })
      .then(({ json }) => ({
        data: json,
      }));
  },

  async verificationLogin(model: any) {
    try {
      return fetchUtils
        .fetchJson(`${apiUrl}/boxapi/verification-login`, {
          method: "POST",
          body: JSON.stringify(model),
        })
        .then(({ json }) => ({
          data: json,
        }));
    } catch {
      return { data: { status: false } };
    }
  },


  
  async sendOTPCodeBuyTime(model: any) {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/verification-code-buytime`, {
        method: "POST",
        body: JSON.stringify(model),
      })
      .then(({ json }) => ({
        data: json,
      }));
  },

  async verificationLoginBuyTime(model: any) {
    try {
      return fetchUtils
        .fetchJson(`${apiUrl}/boxapi/verification-login-buytime`, {
          method: "POST",
          body: JSON.stringify(model),
        })
        .then(({ json }) => ({
          data: json,
        }));
    } catch {
      return { data: { status: false } };
    }
  },

  async payment(model: any) {
    try {
      return fetchUtils
        .fetchJson(`${apiUrl}/boxapi/box-payment`, {
          method: "POST",
          body: JSON.stringify(model),
        })
        .then(({ json }) => ({
          data: json,
        }));
    } catch {
      return { data: { status: false } };
    }
  },

  
  async paymentBuyTime(model: any) {
    try {
      return fetchUtils
        .fetchJson(`${apiUrl}/boxapi/box-payment-buytime`, {
          method: "POST",
          body: JSON.stringify(model),
        })
        .then(({ json }) => ({
          data: json,
        }));
    } catch {
      return { data: { status: false } };
    }
  },

  async getLocations() {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/locations`, {
        method: "GET",
      })
      .then(({ json }) => ({
        data: json,
      }));
  },

  async getLockers(locationCode: string) {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/location/${locationCode}/lockers`, {
        method: "GET",
      })
      .then(({ json }) => ({
        data: json,
      }));
  },
};

const App = () => (
  <Admin dataProvider={appDataProvider} requireAuth authProvider={authProvider}>
   
    {/* <Resource options={{ label: "Prices" }} name="prices" list={Prices} /> */}
    {/* <Resource options={{ label: "Send SMS" }} name="sendsms" list={Prices} />
    <Resource options={{ label: "Check-in" }} name="checkin" list={CheckIn} /> */}
    <Resource options={{ label: "Mail Gönder" }} name="import" list={Import} />
    <Resource
      options={{ label: "Gönderilen Mailler" }}
      name="transactions"
      list={Transactions}
    />

    <CustomRoutes>
      <Route path="/transactions" element={<Transactions />} />
    </CustomRoutes>
    {/* <CustomRoutes>
      <Route path="/prices" element={<Prices />} />
    </CustomRoutes> */}
    <CustomRoutes>
      {/* <Route path="/sendsms" element={<SendSms  />} />
      <Route path="/checkin" element={<CheckIn  />} /> */}
      <Route path="/import" element={<Import  />} />
    </CustomRoutes>
  </Admin>
);

export default App;
