import React, { useEffect, useState } from "react";
import {
  Title, Create,
  Form,
  TextInput,
  SaveButton,
  useEditContext,
  required,
  useDataProvider,
  useNotify,
} from "react-admin";
import { Grid, Card, Typography, CardContent, Button } from "@mui/material";
import XLSX, { read, utils, writeFile } from 'xlsx';
import "./import.css";


const Import = (props: any) => {
  const [__html, setHTML] = React.useState("");
  const [eData, setEdata] = React.useState<any>([]);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [subject, setSubject] = useState<any>("Vadesi Geçen Ödemeler Hakkında");
  const [loading, setLoading] = useState<any>(false);


  const sendMail = async () => {
    setLoading(true);
    const content: any = document.getElementById("mailContentDiv")?.innerHTML;
    await dataProvider.SendMail({
      mailTransactions: eData.map((item: any) => {
        item.cariBakiye = item.cariBakiye + "";
        return item;
      }),
      subject: subject,
      content: content
    }).then(() => {
      notify(`Mail Gönderimi Başarıyla Tamamlandı`, { type: 'success' });
      setTimeout(() => {
        window.location.href = "/#/transactions";
      }, 1000);
    });
  }

  return (<><br />
    <Typography variant="h4" gutterBottom>
      Ödeme Mailleri
    </Typography>
    <Card style={{ marginTop: "1em" }} >
      <Title title={"Import"} />

      <CardContent>
        <Form>
          <Grid container spacing={0}>
            <Grid item xs={4} style={{ paddingRight: "1em" }}>
              <Typography variant="h6" gutterBottom>
                Yeni Gönderim Yap
              </Typography><hr />
              <TextInput
                fullWidth
                source="konu"
                label="Gönderilen Mailin Konusu : "
                placeholder="Vadesi Geçen Ödemeler Hakkında"
                validate={required()}
                value={subject}
                defaultValue="Vadesi Geçen Ödemeler Hakkında"
                onChange={(e) => setSubject(e?.target?.value)}
              />

              <div>
                <input type="file" id="raised-button-file" style={{ display: 'none' }} onChange={async (e: any) => {

                  const file = e.target.files[0];
                  const data = await file.arrayBuffer();

                  const wb = XLSX.read(data);
                  const ws = wb.Sheets[wb.SheetNames[0]];

                  const jsonData = XLSX.utils.sheet_to_json(ws);
                  setEdata(jsonData);




                  setHTML(XLSX.utils.sheet_to_html(ws, { id: "tabeller" }));
                }} />

                <label htmlFor="raised-button-file">
                  <Button variant="contained" component="span" color="info" >
                    GÖNDERİLECEK KİŞİLERİ DOSYADAN YÜKLE
                  </Button>
                </label>
                <br /><br />
                <p>Örnek Excel Dosyasını indirmek için <a target="_blank" href="https://muhasebe.malllogistics.com/SampleImportFile.xlsx">tıklayınız...</a></p>
              </div>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h6" gutterBottom>
                Örnek Mail İçeriği
              </Typography><hr />
              <div id="mailContentDiv">
                <p>Değerli <b>[[title]]</b>; </p>
                <p><b>[[donem]]</b> ve öncesi döneme ait borç bakiyesi bulunan müşterilerimizin ödeme vadelerinin dolduğunu hatırlatır, ilgili tedarik süreçlerinizde aksaklık yaşanmaması adına <b>[[cariBakiye]]</b> TL’lik bakiyenizin <b>[[sonOdemeTarihi]]</b> tarihi mesai bitimine kadar ödenmesini önemle rica ederiz.<br />&nbsp;</p>
                <p>Saygılarımızla,<br />AVM Lojistik Tic. A.Ş.<br />Finans Departmanı</p>
                <br />
                <p style={{ color: "red" }}>İletişim Bilgilerimiz:</p>
                <p>İrem Gül :<a href="mailto:irem.gul@malllogistics.com">irem.gul@malllogistics.com</a></p>
                <br />
                <p style={{ color: "red" }}>Banka Bilgilerimiz: </p>
                <p>Akbank / Maslak Şb<br />TR47 0004 6006 4588 8000 1587 16</p>

                <p>Garanti Bankası / Acıbadem Şb<br />TR20 0006 2000 4040 0006 2949 99 </p>

                <p>Ziraat Bankası / Metrokent Şb<br />TR41 0001 0024 5789 6729 8850 01 </p>
                <br />
                <hr color="red" />
                <ul>
                  <li style={{ color: "red" }}>Bu mail AVM Lojistik Ticaret A.Ş. sistemi tarafından bilgilendirme amaçlı otomatik olarak gönderilmiştir.</li>
                  <li style={{ color: "red" }}>Bu ileti, takip edilmeyen bir e-posta adresinden gönderilmiştir. Lütfen bu iletiyi yanıtlamayınız.</li>
                  <li style={{ color: "red" }}> Bilgi paylaşımlarınız için <a href="mailto:mutabakat@malllogistics.com">mutabakat@malllogistics.com</a> mail adresini kullanabilirsiniz.</li>
                </ul>

                <hr color="red" />
              </div>
              {/* {JSON.stringify(eData)} */}
            </Grid>
            <Grid item xs={4}>


            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={12}>

            </Grid>
          </Grid>


        </Form>
        {__html != "" &&
          <React.Fragment>
            <br /><br /> <div style={{ width: "400px", marginLeft: "auto", marginRight: "auto" }}><Button disabled={loading} onClick={() => sendMail()} color="success" variant="contained">DOSYADAN YÜKLENEN {eData?.length} ALICIYA MAİL GÖNDER</Button></div><br /><br />
            <Typography variant="h6" gutterBottom>
              DOSYA ÖNİZLEME
            </Typography>
            <hr />
          </React.Fragment>
        }

        <div dangerouslySetInnerHTML={{ __html }} />
      </CardContent>
    </Card>
  </>);
}

export default Import;


