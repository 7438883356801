import {
  useList,
  ListContextProvider,
  Datagrid,
  TextField,
  ListBase,
  useDataProvider,
  defaultExporter,
  Button,
  Loading,
  TopToolbar,
  ChipField,
  TextInput,
  Link,
  FunctionField,
  ListToolbar,
  Pagination,
  Title,
} from "react-admin";
import { Card } from "@mui/material";
import Chip from "@mui/material/Chip";
import { useListContext } from "react-admin";

import React, { useEffect, useState } from "react";
import moment from "moment";
import { List, downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import NumbersIcon from "@mui/icons-material/Numbers";
import { utils, writeFileXLSX } from "xlsx";

const exporter = (data) => {
  const dataForExport = data.map((record) => {
    return {
      id: record?.id,
      mail: record?.mail,
      title: record?.title,
      donem: record?.donem,
      sonOdemeTarihi: record?.sonOdemeTarihi,
      createdDate: record?.insertDate,
      content: record?.content,
    };
  });

  const ws = utils.json_to_sheet(dataForExport);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Worksheet1");
  writeFileXLSX(wb, `Transactions-${moment().format("yyyMMDDHHmmss")}.xlsx`);
 
};

const MyList = ({ children, actions, filters, title, ...props }) => (
  <React.Fragment {...props}>
    <div style={{ marginTop: "-2em" }}>
      <Title title={title} />
      <ListToolbar filters={filters} actions={actions} />
      <Card>{children}</Card>
      <Pagination />
    </div>
  </React.Fragment>
);

const ListActions = (data) => {
  return (
    <TopToolbar>
      <Button onClick={() => exporter(data.data)} label="Export" />
    </TopToolbar>
  );
};

const postFilters = (listContext) => {
  const { setFilters } = listContext;

  console.log(setFilters);

  return [
    <TextInput
      label="Search"
      source="q"
      alwaysOn
      onChange={(v) => {
        setFilters({ q: v });
      }}
    />,
    <TextInput
      label="Gsm Number"
      source="gsmNumber"
      alwaysOn
      placeholder="5XX XXX XX XX"
    />,
  ];
};

const Transactions = () => {
  const dataProvider = useDataProvider();
  const [data, setData] = useState(null);
  useEffect(() => {
    if (data == null)
      dataProvider.BoxList().then(({ data }) => {
        setData(data);
      });
  }, []);

  const listContext = useList({ data });

  return data == null ? (
    <Loading
      loadingPrimary="Loading"
      loadingSecondary="Transaction data is loading, just a moment please..."
    />
  ) : (
    <MyList
      // filters={postFilters(listContext)}
      actions={<ListActions data={data} />}
      title="Transactions"
    >
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" label="ID" />
          <TextField source="mail" label="MAIL" />
          <TextField
            source="title"
            label="FİRMA ADI/YETKİLİ LİŞİ"
            style={{ textTransform: "uppercase" }}
          />
          <TextField
            source="donem"
            label="DÖNEM"
            style={{ textTransform: "uppercase" }}
          />
          <TextField
            source="cariBakiye"
            label="CARİ BAKİYE"
            style={{ textTransform: "uppercase" }}
          />
          <FunctionField
            render={(item) => moment(item.sonOdemeTarihi).format("DD.MM.YYYY")}
            source="sonOdemeTarihi"
            label="SON ÖDEME TARİHİ"
            textAlign="center"
          />
          <FunctionField
            render={(item) => moment(item.insertDate).format("DD.MM.YYYY")}
            source="insertDate"
            label="MAİL GÖNDERİM TARİHİ"
            textAlign="center"
          />
        </Datagrid>
      </ListContextProvider>
    </MyList>
  );
};

export default Transactions;

const durations = [
  {
    name: "1 Week",
    value: 8,
  },
  {
    name: "4 Hours",
    value: 2,
  },
  {
    name: "2 Hours",
    value: 1,
  },
  {
    name: "2 Days",
    value: 7,
  },
  {
    name: "1 Day",
    value: 6,
  },
  {
    name: "6 Hours",
    value: 3,
  },
  {
    name: "1 Hour",
    value: 0,
  },
  {
    name: "1 Month",
    value: 9,
  },
  {
    name: "12 Hours",
    value: 4,
  },
  {
    name: "18 Hours",
    value: 5,
  },
];

const paymentTypes = [
  "",
  "Credit Card",
  "Cash",
  "Promotion",
  "Credit Card (POS)",
];
